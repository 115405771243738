import { useClubStore } from '@/stores/club'

export function useClub() {
  const clubStore = computed(() => {
    return useClubStore()
  })
  const currentClub = computed(() => {
    if (clubStore.value.isClub) {
      return clubStore.value
    }
    return null
  })
  const clubName = computed(() => {
    return clubStore.value.name
  })
  const clubLogo = computed(() => {
    return clubStore.value.logo_url
  })
  const isClub = computed(() => {
    return clubStore.value.isClub
  })
  const layout = computed(() => {
    return clubStore.value.layout
  })
  const clubEnablePayment = computed(() => {
    return clubStore.value.clubEnablePayment
  })
  const clubEnableDelivery = computed(() => {
    return clubStore.value.clubEnableDelivery
  })
  const clubConfig = computed(() => {
    return clubStore.value.clubConfig
  })
  const clubUid = computed(() => {
    return clubStore.value.uid
  })
  const clubAds = computed(() => {
    if (isClub.value && clubConfig.value?.product_ads?.length) {
      return clubConfig.value?.product_ads.map(ad => {
        return {
          _uid: ad._uid,
          component: 'GridAd',
          desktop_index: ad.line,
          format: ad.kind === 'LANDSCAPE' ? 'full' : ad.position ? ad.position?.toLowerCase() : null,
          mobile_image: ad.image_url,
          mobile_index: ad.line,
          url: ad.url,
          wide_image: ad.image_url,
        }
      })
    }
  })
  const clubBanners = computed(() => {
    if (isClub.value && clubConfig.value?.banner) {
      return [
        {
          component: 'Slide',
          image_alt: 'club banner',
          mobile_image: clubConfig.value?.banner,
          wide_image: clubConfig.value?.banner,
          testId: 'club-banner',
        },
      ]
    } else if (isClub.value && clubConfig.value?.banners) {
      if (clubConfig.value.banners.some(x => x.desktop_image_url || x.mobile_image_url)) {
        return clubConfig.value.banners.map(banner => {
          return {
            component: 'Slide',
            image_alt: banner.alt,
            mobile_image: banner.mobile_image_url,
            target_value: banner.url,
            wide_image: banner.desktop_image_url,
            testId: 'club-banner',
          }
        })
      }
    }
    return null
  })
  return {
    clubStore,
    currentClub,
    clubName,
    clubLogo,
    isClub,
    layout,
    clubEnablePayment,
    clubEnableDelivery,
    clubConfig,
    clubUid,
    clubAds,
    clubBanners,
  }
}
